@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {
      .background_hero {
        background-image :url('./images/hero.jpg');
        background-position:center;
        background-size:cover;
      }
      .no_background_hero {
        background-image: none;
      }
      .comic{
        font-family:"Fuzzy Bubbles";
      }
    }
  }